import React from 'react';
import { graphql, Link } from 'gatsby';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import makeStyles from '@material-ui/styles/makeStyles';
import HackItem from '../components/HackItem/HackItem';
import DownloadComponent from '../components/DownloadComponent/DownloadComponent';
import AppBar from '../components/AppBar/AppBar';
import Footer from '../components/Footer/Footer';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles(theme => {
  return {
    root: {
      width: '100%',
      paddingTop: '32px',
      backgroundColor: '#F8F8F8',
      [theme.breakpoints.up(1200)]: {
        paddingTop: '152px',
      },
    },
    title: {
      fontSize: '21px',
      lineHeight: '30px',
      fontWeight: '500',
      padding: '0 24px',
      maxWidth: '600px',
      margin: '0 auto 16px',
      [theme.breakpoints.up(1200)]: {
        maxWidth: '1218px',
        fontSize: '34px',
        lineHeight: '40px',
        fontWeight: '700',
      },
    },
    collectionsList: {
      padding: '0 24px',
      display: 'flex',
      flexDirection: 'column',
      gap: '32px',
      maxWidth: '600px',
      margin: '0 auto 32px',
      [theme.breakpoints.up(1200)]: {
        maxWidth: '1218px',
        flexDirection: 'row',
        gap: '28px',
        flexWrap: 'wrap',
      },
    },
    navigationContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      padding: '0 50px',
      marginBottom: '32px',
      '& a': {
        width: '100%',
        fontSize: 16,
        color: theme.palette.text.primary,
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  };
});

const HacksIndexPage = ({ data, pageContext: { totalPages, currentPage, language } }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const hacks = data.allHack.edges;
  return (
    <>
      <AppBar keepBackgroundColor />
      <main className={classes.root}>
        <Typography className={classes.title} variant="h1">
          {t('hackListPage.title')}
        </Typography>
        <div className={classes.collectionsList}>
          {hacks.map(({ node: hack }) => {
            return <HackItem key={hack.id} hack={hack} />
          })}
        </div>
        <div className={classes.navigationContainer}>
          <Pagination
            count={totalPages}
            page={currentPage}
            renderItem={item => (
              <PaginationItem
                component={Link}
                to={`${language.startsWith('en') ? '' : `/${language}`}/hacks${item.page === 1 ? '' : `/${item.page}`}`}
                {...item}
              />
            )}
          />
        </div>
        <DownloadComponent showPhones />
      </main>
      <Footer />
    </>
  );
};
export default HacksIndexPage;

export const query = graphql`
  query HacksIndexPage($limit: Int, $skip: Int, $language: String) {
    allHack(limit: $limit, skip: $skip, filter: {locale: {eq: $language}}) {
      edges {
        node {
          id
          title
          path
          segments {
            inANutshell {
              sections {
                cards {
                  markdown
                }
              }
            }
          }
          contributors {
            name
          }
          topics {
            name
          }
          coverUrl
        }
      }
    }
    locales: allLocale(filter: {language: {in: ["en-US", $language]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
