import React from 'react';
import { Link } from 'gatsby';
import makeStyles from '@material-ui/styles/makeStyles';
import { Typography } from '@material-ui/core';
import useMarkdownRenderer from '../../hooks/useMarkdownRenderer';
import { sectionsToMarkdownString } from '../../utils/hackTools';
import { getLexemFromTopicName } from '../../utils/utils';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles(theme => {
  return {
    root: {
      width: '100%',
      background: '#fff',
      borderRadius: '0 8px 8px 8px',
      textDecoration: 'none',
      boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.1)',
      [theme.breakpoints.up(1200)]: {
        flex: '0 0 calc(33.333333% - 19px)',
      },
    },
    image: {
      paddingTop: '63.34%',
      height: 0,
      width: '100%',
      borderRadius: '0 8px 8px 8px',
      backgroundSize: 'cover',
    },
    details: {
      padding: '16px 16px 32px',
    },
    tagsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      flexWrap: 'wrap',
      gap: '4px',
      marginBottom: '8px',
    },
    tag: {
      padding: '4px',
      fontSize: '10px',
      lineHeight: '14px',
      color: theme.palette.primary.main,
      fontWeight: '400',
      backgroundColor: '#E9E9EC',
    },
    title: {
      fontSize: '17px',
      lineHeight: '22px',
      fontWeight: '500',
      color: theme.palette.primary.main,
      marginBottom: '8px',
    },
    contribs: {
      fontSize: '13px',
      lineHeight: '16px',
      fontWeight: '400',
      color: '#4F4F4F',
      marginBottom: '8px',
    },
    inANutshell: {
      color: theme.palette.primary.main,
      '& p': {
        fontSize: '17px',
        lineHeight: '22px',
        margin: '0',
      }
    },
  };
});

const { renderer, marked } = useMarkdownRenderer();
const getMarkdownText = text => {
  return { __html: marked(text, { renderer }) };
};

const HackItem = ({
  hack
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Link
      className={classes.root}
      to={hack.path}
    >
      <div className={classes.details}>
        <div className={classes.tagsContainer}>
          {hack.topics.map(({ name: tag }) => {
            return (
              <div key={tag} className={classes.tag}>
                {t(getLexemFromTopicName(tag))}
              </div>
            )
          })}
        </div>
        <Typography className={classes.title} variant="body1">
          {hack.title}
        </Typography>
        <Typography className={classes.contribs} variant="body1">
          {hack.contributors.map(({ name }) => {
            return name;
          }).join(', ')}
        </Typography>
        <div
          className={classes.inANutshell}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={getMarkdownText(sectionsToMarkdownString(hack.segments.inANutshell.sections))}
        />
      </div>
    </Link>
  );
}

export default HackItem;
